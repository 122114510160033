.skills {
  position: relative;
  text-align: center;
}
.skills-content {
  margin: 0 auto;
  max-width: 1500px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
  overflow-wrap: break-word;
}

.textLink {
  color: #0be779;
  cursor: pointer;
}
